import { Inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { IUser } from "../models/IUser";
import * as jwt_decode from "jwt-decode";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";


@Injectable({
  providedIn: "root"
})
export class AuthService {
  public currentUser: IUser;
  private _userSubject = new Subject<IUser>();
  public userObservable = this._userSubject.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document, private router: Router) {
    if (this.getToken()) {
      this.authenticate(this.getToken());
    }
  }

  login(token: string): void {
    this.authenticate(token);
    this.router.navigate(["/dashboard"]);
  }

  authenticate(token: string): void {
    this.document.defaultView?.localStorage?.setItem("token", token);
    this.currentUser = jwt_decode.jwtDecode(token) as IUser;
    this._userSubject.next(this.currentUser);
  }

  logout(): void {
    this.document.defaultView?.localStorage?.removeItem("token");
    this.currentUser = null;
    this._userSubject.next(this.currentUser);
    this.router.navigateByUrl("/auth/login");
  }

  getToken(): string {
    return this.document.defaultView?.localStorage?.getItem("token");
  }
}
